.loaderDiv{
    position: fixed;
    z-index: 9999 !important;
    width: 100%;
    height: 100%;
    background: #ffffffab;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loaderImage{
    width: 200px;
}