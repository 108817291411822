// Here you can add other styles
$fontSize: 12px;

.sidebar-nav .nav-link{
    font-size: $fontSize;
    padding: 0.5rem 1rem;
}

.sidebar-nav .nav-link.active{
    text-shadow: 3px 3px 2px #4c4b4b;
    background: #d19f35d1!important;
}

.sidebar{
    --cui-sidebar-width: 18rem;
}

.sidebar:not(.sidebar-end) ~ *{
    --cui-sidebar-occupy-start: 18rem;
}

.sidebar-narrow-unfoldable:not(.sidebar-end) ~ * {
    --cui-sidebar-occupy-start: 4rem!important;
}

.nav-icon{
    font-size: 10px!important;
}

.card-group{
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.footerDiv{
    justify-content: space-between;
}

html:not([dir=rtl]) .ms-auto, .ms-auto{
    margin-left:0px!important;
}

@media (min-width: 768px){
    .sidebar.hide:not(.sidebar-end) ~ * {
        --cui-sidebar-occupy-start: 0!important;
    }
}

@media (max-width: 768px){
    .footerDiv{
        justify-content: center;
    }
    
    .sidebar-narrow-unfoldable:not(.sidebar-end) ~ * {
        --cui-sidebar-occupy-start: 0px!important;
    }
}

@media (max-width: 975px){
    .card-group{
        display: flex;
        flex-direction: column-reverse;
    }
}

// SIZE : XL Modal Style
.modal{
    width: 100%!important;
}

.modal-xl{
    --bs-modal-width: 95%!important;
}
// SIZE : XL Modal Style
body::-webkit-scrollbar {
    width: 1em!important;
}

body::-webkit-scrollbar-track {
box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3)!important;
}

body::-webkit-scrollbar-thumb {
background-color: darkgrey!important;
outline: 1px solid slategrey!important;
}